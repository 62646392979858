.token-file-icon svg{
  width: 0.7em;
  height: 0.7em;
  vertical-align: -webkit-baseline-middle;
}

.contract-link {
  color: #3340e4;
  cursor: pointer;
}

.token-uploading-icon svg {
  width: 0.7em;
  height: 0.7em;
  color: #00BD8E;
}

.token-footer-icon svg {
  width: 0.8em;
  height: 0.8em;
  color: #3948FF;
}

.MuiFormControl-marginNormal {
  width: 100%;
}

.MuiInputAdornment-positionStart .MuiIconButton-root {
  padding: 16px 3px 16px 16px!important;
}

.MuiInputBase-root {
  color: rgba(0, 0, 0, 0.6)!important;
}

.MuiInput-underline:before {
  border: none!important;
}

.selectTokenList > div {
  max-height: 98%;
}

.selectTokenList {
  overflow: scroll;
}

.pending-token .avatar {
  width: 35px!important;
  height: 35px!important;
  margin-right: 10px;
}

.crowdsale-input {
  z-index: 999;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -22px;
  height: 47px;
  width: 48px;
  border-radius: 50%!important;
  cursor: pointer;
}

.autocomplete-root {
  width: 100%;
}

.autocomplete-root-input {
  background: #F0F0F0;
  width: 100%;
  height: 56px;
  padding: 0px 18px;
  display: inline-flex;
}

.autocomplete-root-input input {
  background: #F0F0F0;
  width: 100%;
  padding: 0px 10px;
  height: 56px;
  outline: none;
}

.autocomplete-root-input svg {
  color: #5D5D5D;
  vertical-align: -webkit-baseline-middle;
}

.crowdsale-detail-icon svg {
  width: 18px;
  height: 22px;
  vertical-align: -webkit-baseline-middle;
  color: #474747;
}


.crowdsale-detail-font{
  font-size: 14px;
  vertical-align: middle;
}
.token-goal-font{
  font-weight: bold;
  color: #3752F5;
}
.buynow-btn{
  width: 250px;
  height: 40px;
  border-radius: 25px !important;
  align-self: center;
  padding: 0 !important;
  font-size: 14px;
}

.ellipsi-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.popover__content:before {
  position: absolute;
  z-index: 0;
  content: "";
  right: calc(50% - 10px);
  bottom: -10px;
  border-style: solid;
  border-width: 10px 10px 0px 10px;
  border-color: #000000cf transparent transparent transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}