body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* custom-styles */
.app-wrapper{
  max-width: 768px;
  margin: 0 auto;
}

.react-draggable {
  z-index: 12 !important;
}

.set-height .react-draggable {
  min-height: 32em !important;
}

@media only screen and (max-height: 800px) {
  .set-height .react-draggable {
    min-height: 27em !important;
  }
}

.head-title {
  position: absolute;
}

/* .dragger-wrapper  */
.dragger-wrapper .react-draggable{
  transition: transform .18s ease-out !important;
}

.upload-icon-loader {
  animation: rotate 3s linear reverse infinite;
}

@keyframes rotate { 100% { transform:rotate(-360deg); } }
